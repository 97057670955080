import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Navbar from '../components/navbar/Navbar';

//Tabla
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//footer
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import axios from "axios";
import Swal from "sweetalert2";
import Input from '@mui/material/Input';
import API from "../services/config"

import "../sass/Tablas.sass"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#000',
  },
}));
const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n+1)': {
    backgroundColor: '#e7e7e7',

  },
  '&:nth-of-type(2n)': {
    backgroundColor: '#ffffff',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
  margin: '8px 0',
  // hide last border

}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function PrimerTorneo() {
  window.sessionStorage.setItem("one", "true");
  window.sessionStorage.setItem("two", "true");
  window.sessionStorage.setItem("four", "true");
  window.sessionStorage.setItem("three", "false");
  window.sessionStorage.setItem("text1", "");


  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([]);
  const [Once, setOnce] = useState([]);
  const [wordEntered, setWordEntered] = useState("");

  function createData2(posicion, usuario, valorApostado, valorPremio) {
    return { posicion, usuario, valorApostado, valorPremio };
  }

  //pagination - footer
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const fractionedDataTable1 = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let dataTable = await axios.get(
      API.baseURL + "api/get/primer-torneo/slice/0/50"
    );
    setFilteredData(dataTable.data.primertorneos);

    if (Once.length === 0) {
      setOnce([{}]);
    }
  };

  const fractionedDataTable2 = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let dataTable = await axios.get(
      API.baseURL + "api/get/segundo-torneo/slice/0/50"
    );
    setFilteredData(dataTable.data.segundotorneos);

    if (Once.length === 0) {
      setOnce([{}]);
    }

  };

  if (filteredData.length < 1 && Once.length === 0) {
    setOnce([{ papa: 'hola' }]);
    fractionedDataTable1().then(() => {
      setTimeout(() => {
        Swal.close();
      }, 750)
    })
  }

  //search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let searchWord = '';
      if (searchTerm === '') {
        searchWord = '!';
      } else { searchWord = searchTerm; }
      //handleFilter(searchWord);
    }, 450)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const searchHandleResults = () => {
    let searchWord = '';
    if (searchTerm === '') {
      searchWord = '!';
    } else { searchWord = searchTerm; }
    handleFilter(searchWord);

  }

  const handleFilter = async (event) => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    /*const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.usuario.toLowerCase().includes(searchWord.toLowerCase());
    });

    i
    }*/
    //---------------------
    //console.log(event);
    const searchWord = event;
    let filterWord;
    const pattern = new RegExp('^[A-Z0-9]+$', 'i');

    setWordEntered(searchWord);

    if (!pattern.test(searchWord)) {
      filterWord = '!'
    } else {
      filterWord = searchWord
    }

    let filteredDataAxi;


    filteredDataAxi = await axios.get(
      API.baseURL + "api/get/primer-torneo/search/" + filterWord
    );

    /*filteredDataAxi = await axios.get(
      API.baseURL + "api/get/segundo-torneo/search/" + filterWord
    );*/

    if (filteredDataAxi.data.resultado.length < 11 && window.innerWidth < 992 && window.innerHeight > window.innerWidth) {
      let trya = filteredDataAxi.data.resultado
      const limite = 10 - filteredDataAxi.data.resultado.length
      for (let i = 0; i < limite; i++) {
        trya.push({ posicion: '-', usuario: '-', valorApostado: '-', valorPremio: '-', })
      }
      setFilteredData(trya)
    } else {
      setFilteredData(filteredDataAxi.data.resultado)
    }

    //console.log(filteredDataAxi.data.resultado);

    Swal.close();
  };

  // Avoid a layout jump when reaching the last page with empty rows.


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tablaTorneo = () => {



    let auxRows = []

    filteredData.forEach(element => {
      auxRows.push(createData2(element.posicion, element.usuario, element.valorApostado, element.valorPremio, 0));
    });

    const rows = auxRows

    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    let newFontSize = 14

    if (window.innerWidth < 1100) {
      newFontSize = 14
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: newFontSize }} className='row_table' align="center"><h4>Posición</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Usuario</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Puntaje</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Premio</h4></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center">
                  <h4>{row.posicion}</h4>
                </StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.usuario}</h4></StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.valorApostado}</h4></StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.valorPremio}</h4></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {window.innerWidth > 992
                ? <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={4}
                  count={rows.length}
                  labelRowsPerPage={""}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Celdas visibles',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
                : window.innerHeight < 400
                  ? <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    colSpan={4}
                    count={rows.length}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Celdas visibles',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                  : <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    colSpan={4}
                    count={rows.length}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Celdas visibles',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
              }

            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>

      <body className='body_primer_torneo'>
        <div className='container_tablas'>
          <div className='content_area group'>
            <aside className='left_area_torneo'>
              <Navbar id="navbarsito" />
            </aside>
            <div className='main_area_torneo'>

              {window.innerWidth > 992
                ?
                <>
                  <a href="/" >
                    <img
                      src={require("../images/new/asdbetprpro2.png")}
                      width="50%"
                      className="d-inline-block align-top"
                      alt="Logo de eliminatorias StarPlay"
                    />
                  </a>
                </>

                :
                <div className='logo_torneo'>
                  <a href="/" >
                    <img
                      src={require("../images/new/asdbetprpro3.png")}
                      width="70%"
                      className="d-inline-block align-top"
                      alt="Logo de eliminatorias StarPlay responsive"
                    />
                  </a>

                </div>
              }
              <h4 className='h4_title'>
                Ingresa los cinco últimos dígitos de tu cédula
              </h4>
              <div>
                <div className='buscar_input_div'>
                  <Input className='buscar_input'
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder='Buscar...'
                  />
                </div>
                <div className='buscar_button_div'>
                  <Button className='buscar_button' onClick={searchHandleResults}>
                    Buscar
                  </Button>
                </div>
              </div>
              <div className='area_tablas'>
                {tablaTorneo()}
              </div>
            </div>


          </div>

        </div>
        <footer>

        </footer>
      </body>
    </>
  );
}

export default PrimerTorneo;