import React, { useState, useEffect } from 'react'
import { Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import "../sass/Inicio.sass"

function Inicio() {
  window.sessionStorage.setItem("one", "true");
  window.sessionStorage.setItem("two", "true");
  window.sessionStorage.setItem("four", "true");
  window.sessionStorage.setItem("three", "false");
  window.sessionStorage.setItem("text1", "");

  /*const renderprueba = () => {
    const promociones = [{url:producto1,nombre:'Premio 1'},
                        {url:producto2,nombre:'Premio 2'},
                        {url:producto3,nombre:'Premio 3'},
                        {url:producto4,nombre:'Premio 4'}]
    return (
      <>
        <Box sx={{ flexGrow: 1 }} >
          <Grid style={{marginLeft:'-5%'}}
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 1, sm: 1, md: 12 }}
          >
            {promociones.map(promocion => (
              <Grid  
              item xs={6} sm={6} md={3} key={promocion.nombre}>
                <div>
                <img
                    src={promocion.url}
                    height="30%"
                    width="25%"

                    className="d-inline-block align-top"
                    alt="React Bootstrap logo"
                />
                  <h4 style={{marginLeft:'25%'}}>{promocion.nombre}</h4>
                </div>
              </Grid>
            ))}
          </Grid>
        </Box>

      </>
    )
  }*/

  return (
    <>
      <body className='body_inicio'>
        <div className='container'>
          <div className='content_area group'>
            <div className='main_area'>
              <a href="/primer-torneo" >
                {window.innerWidth > 992
                  ?
                  <>
                    <img
                      // height="80%"
                      // width="60%"
                      src={require("../images/new/asdbetprpro2.png")}
                      className="d-inline-block align-top"
                      alt="Logo de eliminatorias StarPlay"
                    />
                  </> :
                  <div style={{ textAlign: 'center'}}>
                  <img
                      // height="80%"
                      width="70%"
                      src={require("../images/new/asdbetprpro3.png")}
                      className="d-inline-block align-top"
                      alt="Logo de eliminatorias StarPlay"
                    />
                  </div>
                }
              </a>
              {/*<h4>
                  Texto sobre promociones o el tipo de torneo que se quiere visualizar
                  {
                  //Apuesta, acumula puntos y tendrás la oportunidad de ganar ENTRADAS DOBLES con todo incluido al partido y muchos premios más...
                  }
                </h4>*/}
              {/*<img
                     
                      width="100%"
                      alt="Equipo vs Equipo"
                  />
                  <h4 style={{marginLeft:'20%'}}>
                    y muchos premios más...
                  </h4>*/}
              <div className='imagenes_promocion'>

                {/*renderprueba()*/}


              </div>
              <div>
                <a href='/primer-torneo'>
                  <Button className='inicio_button'>
                    ingresa aquí
                  </Button>
                </a>
              </div>

            </div>
          </div>
        </div>
      </body>

    </>
  );
}

export default Inicio;