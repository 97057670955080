import React from 'react';
import * as RiIcons from 'react-icons/ri';

export const SidebarData = [
  {
    title: 'PrimerTorneo',
    path: '/primer-torneo',
    icon: <RiIcons.RiNumber1 />,
    cName: 'nav-text'
  },
  {
    title: 'SegundoTorneo',
    path: '/segundo-torneo',
    icon: <RiIcons.RiNumber2 />,
    cName: 'nav-text'
  },
];